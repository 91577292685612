import "./style.scss";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MarketEvaluation from "../MarketEvaluation";
import FinanceEvaluation from "../FinanceEvaluation";
import Overview from "../../layouts/Overview";
import StockChart from "../../components/StockChart";

import { companyListData } from "../../../company-list.js";

const types: string[] = [
  "總覽",
  "價值投資",
  "財務分析",
  "交易資訊",
  "大戶指標",
];
const financeSubtitles: string[] = [
  "重要指標",
  "每月營收",
  "損益表",
  "資產負債表",
  "現金流量表",
];

const USStockPage = () => {
  const { tickerId } = useParams();
  const [loading] = useState(false);
  const [active, setActive] = useState<string>(types[0]);
  const [companyTitle, setCompanyTitle] = useState<string>("");
  const [finacesubtitle, setFinacensubtitle] = useState<string>(
    financeSubtitles[0]
  );
  const navigate = useNavigate();

  const findCompanyName = () => {
    let comp = companyListData.find(
      (item: any) => String(item.id).toLowerCase() === tickerId?.toLowerCase()
    );
    if (comp === undefined) {
      setCompanyTitle(tickerId || "");
    } else {
      setCompanyTitle(comp!.title);
    }
  };

  useEffect(() => {
    findCompanyName();
    // call api to get data here
  }, []);

  return (
    <div className="wrapper">
      <div className="container">
        <header className="header-title">
          <h1>
            {companyTitle} ({tickerId}) 股票分析
          </h1>
          <p>
            {companyTitle} 股票代號 {tickerId} | 美國上市
          </p>
          <ul className="data-items">
            {types.map((type, idx) => (
              <li
                key={idx}
                onClick={() => setActive(types[idx])}
                className={active === type ? "selected" : ""}
              >
                {type}
              </li>
            ))}
          </ul>
          <div className="subtitle-container">
            {active == types[2] && (
              <>
                {financeSubtitles.map((item) => (
                  <span
                    className={finacesubtitle == item ? "selected" : ""}
                    onClick={() => setFinacensubtitle(item)}
                  >
                    {item}
                  </span>
                ))}
              </>
            )}
          </div>
        </header>

        {active == types[0] && tickerId && (
          <Overview ticker={tickerId} market="us" />
        )}
        {active == types[1] && <MarketEvaluation ticker={tickerId} />}
        {active == types[2] && (
          <FinanceEvaluation
            ticker={tickerId}
            subtitle={finacesubtitle}
            companyTitle={companyTitle}
          />
        )}
        {active == types[3] && <></>}
      </div>
    </div>
  );
};

export default USStockPage;
