import { useEffect, useRef, useState } from "react";
import {
  createChart,
  IChartApi,
  ISeriesApi,
  LineStyle,
} from "lightweight-charts";
import { Spin, Button, Checkbox, Modal, Form, Input } from "antd";
import "./style.scss";
import { getTechDailyData } from "../../server/Tech/index";
import type { CheckboxOptionType } from "antd/es/checkbox/Group";

type StockChartProps = {
  chartData: Array<{
    time: string;
    open: number;
    high: number;
    low: number;
    close: number;
    volume: number;
  }>;
  loading?: boolean;
  ticker: string;
  market: "tw" | "us";
};

interface TechData {
  date: string;
  open: number | null;
  high: number | null;
  low: number | null;
  close: number | null;
  volume: number | null;
  SMA5: number | null;
  SMA20: number | null;
  SMA60: number | null;
  EMA5: number | null;
  EMA12: number | null;
  EMA20: number | null;
  EMA26: number | null;
  EMA40: number | null;
  MACD: number | null;
  signal_line: number | null;
  RSI7: number | null;
  RSI14: number | null;
  RSI21: number | null;
}

interface IndicatorGroup {
  id: string;
  name: string;
  indicators?: {
    type: string;
    periods: string[];
  }[];
  data?: {
    [key: string]: {
      [key: string]: number | ImpliedMovement;
    };
  };
}

interface ImpliedMovement {
  "-2σ": number;
  "-σ": number;
  "+σ": number;
  "+2σ": number;
}

interface USStockLevels {
  [key: string]: number | ImpliedMovement;
}

const defaultGroups: Record<"tw" | "us", IndicatorGroup[]> = {
  tw: [],
  us: [
    {
      id: "us-levels",
      name: "GEX",
      data: {
        "^GSPC": {
          "Key Delta": 5000.0,
          "Gamma Flip": 6090.0,
          "Gamma Flip CE": 6090.0,
          "Put Wall CE": 6120.0,
          "Call Wall CE": 6145.0,
          "Put Dominate": 5890.0,
          "Implied Movement": {
            "-2σ": 6078.3,
            "-σ": 6093.61,
            "+σ": 6165.55,
            "+2σ": 6180.86,
          },
          "Put Wall & Large Gamma 1 & Gamma Field": 6000.0,
          "Call Dominate": 6225.0,
          "Large Gamma 2 & Gamma Field CE & Call Wall": 6100.0,
        },
        QQQ: {
          "Call Dominate": 542.0,
          "Large Gamma 1 & Gamma Field & Call Wall": 535.0,
          "Implied Movement": {
            "-2σ": 533.88,
            "-σ": 535.72,
            "+σ": 544.6,
            "+2σ": 546.44,
          },
          "Large Gamma 2 & Call Wall CE": 540.0,
          "Put Dominate": 507.0,
          "Gamma Flip": 527.5,
          "Put Wall": 520.0,
          "Put Wall CE & Gamma Field CE": 525.0,
          "Gamma Flip CE": 537.0,
          "Key Delta": 530.0,
        },
        TSLA: {
          "Gamma Flip CE": 352.5,
          "Put Dominate": 335.0,
          "Large Gamma 2 & Call Wall & Call Wall CE": 360.0,
          "Put Wall CE": 340.0,
          "Implied Movement": {
            "-2σ": 325.87,
            "-σ": 340.96,
            "+σ": 367.86,
            "+2σ": 382.95,
          },
          "Call Dominate": 370.0,
          "Key Delta": 400.0,
          "Put Wall & Large Gamma 1 & Gamma Field & Gamma Field CE & Gamma Flip": 350.0,
        },
      },
    },
  ],
};

const StockChart = ({
  chartData,
  loading = false,
  ticker,
  market,
}: StockChartProps) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<IChartApi | null>(null);
  const candleSeriesRef = useRef<ISeriesApi<"Candlestick"> | null>(null);
  const smaSeriesRef = useRef<ISeriesApi<"Line">[]>([]);
  const emaSeriesRef = useRef<ISeriesApi<"Line">[]>([]);
  const bollingerBandsRef = useRef<ISeriesApi<"Line">[]>([]);
  const macdRef = useRef<ISeriesApi<"Line">[]>([]);
  const rsiRef = useRef<ISeriesApi<"Line">[]>([]);
  const vwapRef = useRef<ISeriesApi<"Line"> | null>(null);

  const [linePoints, setLinePoints] = useState<
    { time: string; price: number }[]
  >([]);
  const [drawingLine, setDrawingLine] = useState(false);
  const [activeTools, setActiveTools] = useState<string[]>([]);
  const [techData, setTechData] = useState<TechData[]>([]);
  const [selectedEMA, setSelectedEMA] = useState<string[]>([]);
  const [selectedRSI, setSelectedRSI] = useState<string[]>([]);
  const [selectedSMA, setSelectedSMA] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [groups, setGroups] = useState<IndicatorGroup[]>([]);
  const [form] = Form.useForm();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [activeGexLines, setActiveGexLines] = useState<ISeriesApi<"Line">[]>(
    []
  );

  const emaOptions = [
    { label: "EMA5", value: "5" },
    { label: "EMA12", value: "12" },
    { label: "EMA20", value: "20" },
    { label: "EMA26", value: "26" },
    { label: "EMA40", value: "40" },
  ];

  const rsiOptions = [
    { label: "RSI7", value: "7" },
    { label: "RSI14", value: "14" },
    { label: "RSI21", value: "21" },
  ];

  const smaOptions = [
    { label: "SMA5", value: "5" },
    { label: "SMA20", value: "20" },
    { label: "SMA60", value: "60" },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setActiveTools((prev) =>
          prev.filter((tool) => !["ema", "sma", "rsi"].includes(tool))
        );
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEMAChange = (checkedValues: CheckboxOptionType[]) => {
    setSelectedEMA(checkedValues.map((v) => v.toString()));
  };

  const handleRSIChange = (checkedValues: CheckboxOptionType[]) => {
    setSelectedRSI(checkedValues.map((v) => v.toString()));
  };

  const handleSMAChange = (checkedValues: CheckboxOptionType[]) => {
    setSelectedSMA(checkedValues.map((v) => v.toString()));
  };

  useEffect(() => {
    if (!chartContainerRef.current || loading || !chartData?.length) return;

    chartRef.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: window.innerHeight * 0.5,
      layout: {
        background: { color: "#ffffff" },
        textColor: "#333",
      },
    });

    const chart = chartRef.current;
    const candleSeries = chart.addCandlestickSeries({
      upColor: "#26a69a",
      downColor: "#ef5350",
      borderVisible: false,
      wickUpColor: "#26a69a",
      wickDownColor: "#ef5350",
    });

    candleSeries.setData(chartData);
    candleSeriesRef.current = candleSeries;

    const volumeSeries = chart.addHistogramSeries({
      color: "#8884d8",
      priceFormat: { type: "volume" },
      priceScaleId: "",
    });

    volumeSeries.setData(
      chartData.map((d) => ({
        time: d.time,
        value: d.volume,
        color:
          d.close >= d.open
            ? "rgba(38, 166, 154, 0.25)"
            : "rgba(239, 83, 80, 0.25)",
      }))
    );

    const updateChartSize = () => {
      if (
        chartContainerRef.current &&
        chartRef.current &&
        chartRef.current.timeScale
      ) {
        const container = chartContainerRef.current;
        const toolbarWidth = 80;
        const gap = 16;
        const availableWidth = container.clientWidth - toolbarWidth - gap;
        const containerHeight = container.clientHeight;

        const finalWidth = Math.max(availableWidth, 300);

        chartRef.current.applyOptions({
          width: finalWidth,
          height: containerHeight,
        });

        chartRef.current.timeScale().fitContent();

        if (candleSeriesRef.current) {
          candleSeriesRef.current.setData(chartData);
        }
      }
    };

    window.addEventListener("resize", updateChartSize);

    return () => {
      window.removeEventListener("resize", updateChartSize);
      if (chart) {
        chart.remove();
      }
    };
  }, [chartData, loading]);

  const handleChartClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!chartRef.current || !candleSeriesRef.current || !drawingLine) return;

    const chart = chartRef.current;
    const timeScale = chart.timeScale();
    const series = candleSeriesRef.current;

    const rect = chartContainerRef.current?.getBoundingClientRect();
    if (!rect) return;

    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const time = timeScale.coordinateToLogical(x);
    const price = series.coordinateToPrice(y);

    if (time === null || price === null) return;

    const index = Math.round(time);
    if (index < 0 || index >= chartData.length) return;

    const selectedTime = chartData[index].time;

    setLinePoints((prevPoints) => {
      const newPoints = [...prevPoints, { time: selectedTime, price }];
      if (newPoints.length === 2) {
        drawLine(newPoints[0], newPoints[1]);
        return [];
      }
      return newPoints;
    });
  };
  const linesRef = useRef<ISeriesApi<"Line">[]>([]);

  let hasAlerted = false;
  const drawLine = (
    point1: { time: string; price: number },
    point2: { time: string; price: number }
  ) => {
    if (!chartRef.current) return;
    if (new Date(point1.time).getTime() >= new Date(point2.time).getTime()) {
      if (!hasAlerted) {
        alert("請選擇按時間升序的兩個點來繪製線條！");
        hasAlerted = true;
      }
      return;
    }

    const lineSeries = chartRef.current.addLineSeries({
      color: "blue",
      lineWidth: 2,
      lineStyle: LineStyle.Solid,
    });

    lineSeries.setData([
      { time: point1.time, value: point1.price },
      { time: point2.time, value: point2.price },
    ]);

    linesRef.current.push(lineSeries);
  };

  const clearLines = () => {
    if (!chartRef.current) return;

    linesRef.current.forEach((line) => {
      chartRef.current?.removeSeries(line);
    });

    linesRef.current = [];
  };
  const calculateBollingerBands = (data: typeof chartData, period: number) => {
    const bandsData: {
      time: string;
      middle: number;
      upper: number;
      lower: number;
    }[] = [];

    for (let i = 0; i < data.length; i++) {
      if (i < period - 1) continue;

      const slice = data.slice(i - period + 1, i + 1);
      const avg = slice.reduce((sum, item) => sum + item.close, 0) / period;

      const variance =
        slice.reduce((sum, item) => sum + Math.pow(item.close - avg, 2), 0) /
        period;
      const stdDev = Math.sqrt(variance);

      bandsData.push({
        time: data[i].time,
        middle: avg,
        upper: avg + 2 * stdDev,
        lower: avg - 2 * stdDev,
      });
    }

    return bandsData;
  };

  const addBollingerBands = () => {
    if (!chartRef.current || !chartData.length) return;
    if (bollingerBandsRef.current.length > 0) return;

    const bandsData = calculateBollingerBands(chartData, 20);

    const middleBandSeries = chartRef.current.addLineSeries({
      color: "blue",
      lineWidth: 2,
      title: "BB Middle",
      lastValueVisible: true,
      priceLineVisible: false,
    });
    middleBandSeries.setData(
      bandsData.map(({ time, middle }) => ({ time, value: middle }))
    );

    const upperBandSeries = chartRef.current.addLineSeries({
      color: "gray",
      lineWidth: 2,
      lineStyle: LineStyle.Dotted,
      title: "BB Upper",
      lastValueVisible: true,
      priceLineVisible: false,
    });
    upperBandSeries.setData(
      bandsData.map(({ time, upper }) => ({ time, value: upper }))
    );

    const lowerBandSeries = chartRef.current.addLineSeries({
      color: "gray",
      lineWidth: 2,
      lineStyle: LineStyle.Dotted,
      title: "BB Lower",
      lastValueVisible: true,
      priceLineVisible: false,
    });
    lowerBandSeries.setData(
      bandsData.map(({ time, lower }) => ({ time, value: lower }))
    );

    bollingerBandsRef.current = [
      middleBandSeries,
      upperBandSeries,
      lowerBandSeries,
    ];
  };

  const calculateVWAP = (data: typeof chartData) => {
    let cumulativeVolume = 0;
    let cumulativeVWAP = 0;

    return data.map((d) => {
      cumulativeVolume += d.volume;
      cumulativeVWAP += d.close * d.volume;
      return { time: d.time, value: cumulativeVWAP / cumulativeVolume };
    });
  };

  const addSMA = () => {
    if (!chartRef.current || !chartData.length) return;
    if (smaSeriesRef.current.length > 0) return;
    if (!Array.isArray(techData) || techData.length === 0) {
      console.warn("Technical data is not available");
      return;
    }
    const smaData = techData
      .map((d) => ({
        time: d.date.substring(0, 10),
        value: d.SMA20,
      }))
      .filter((d) => d.value !== null && typeof d.value === "number");
    const smaSeries = chartRef.current.addLineSeries({
      color: "blue",
      lineWidth: 3,
    });
    smaSeries.setData(smaData);
    smaSeriesRef.current.push(smaSeries);
  };

  const addEMA = () => {
    if (!chartRef.current || !chartData.length) return;
    if (emaSeriesRef.current.length > 0) return;
    if (!Array.isArray(techData) || techData.length === 0) {
      console.warn("Technical data is not available");
      return;
    }
    const emaData = techData
      .map((d) => ({
        time: d.date.substring(0, 10),
        value: d.EMA20,
      }))
      .filter((d) => d.value !== null && typeof d.value === "number");
    const emaSeries = chartRef.current?.addLineSeries({
      color: "red",
      lineWidth: 3,
    });
    if (emaSeries) {
      emaSeries.setData(emaData);
      emaSeriesRef.current.push(emaSeries);
    }
  };
  const addIndicators = (type: string) => {
    if (!chartRef.current || !chartData.length) return;

    if (type === "macd" && macdRef.current.length === 0) {
      const macdData = techData
        .map((d) => ({
          time: d.date.substring(0, 10),
          macd: d.MACD,
          signal: d.signal_line,
        }))
        .filter(
          (d) =>
            d.macd !== null &&
            typeof d.macd === "number" &&
            d.signal !== null &&
            typeof d.signal === "number"
        );

      const macdLineSeries = chartRef.current.addLineSeries({
        color: "blue",
        lineWidth: 1,
      });
      macdLineSeries.setData(
        macdData.map(({ time, macd }) => ({ time, value: macd! }))
      );

      const signalLineSeries = chartRef.current.addLineSeries({
        color: "red",
        lineWidth: 1,
      });
      signalLineSeries.setData(
        macdData.map(({ time, signal }) => ({ time, value: signal! }))
      );

      macdRef.current = [macdLineSeries, signalLineSeries];
    }

    if (type === "rsi" && rsiRef.current.length === 0) {
      const rsiData = techData
        .map((d) => ({
          time: d.date.substring(0, 10),
          value: d.RSI14,
        }))
        .filter((d) => d.value !== null && typeof d.value === "number");
      if (rsiData.length > 0) {
        const rsiSeries = chartRef.current.addLineSeries({
          color: "purple",
          lineWidth: 1,
          priceFormat: {
            type: "price",
            precision: 2,
            minMove: 0.01,
          },
        });
        rsiSeries.setData(rsiData);
        rsiRef.current.push(rsiSeries);
      }
    }

    if (type === "vwap" && !vwapRef.current) {
      const vwapData = calculateVWAP(chartData);
      vwapRef.current = chartRef.current.addLineSeries({
        color: "orange",
        lineWidth: 2,
      });
      vwapRef.current.setData(vwapData);
    }
  };
  const handleToolChange = (tool: string) => {
    if (!chartRef.current) return;

    if (tool === "ema-combo") {
      const emaComboGroup = {
        id: "ema-combo",
        name: "EMA Combo",
        indicators: [
          {
            type: "ema",
            periods: ["5", "20", "40"],
          },
          {
            type: "rsi",
            periods: ["14"],
          },
        ],
      };
      handleGroupClick(emaComboGroup);
      setActiveTools((prev) =>
        activeTools.includes(tool)
          ? prev.filter((t) => t !== tool)
          : [...prev, tool]
      );
      return;
    }

    if (activeTools.includes(tool)) {
      setActiveTools((prev) => prev.filter((t) => t !== tool));
      try {
        switch (tool) {
          case "line":
            setDrawingLine(false);
            setLinePoints([]);
            clearLines();
            break;
          case "bollinger":
            bollingerBandsRef.current.forEach((series) => {
              if (series) chartRef.current?.removeSeries(series);
            });
            bollingerBandsRef.current = [];
            break;
          case "macd":
            if (macdRef.current.length > 0) {
              macdRef.current.forEach((series) => {
                if (series) chartRef.current?.removeSeries(series);
              });
              macdRef.current = [];
            }
            break;
          case "vwap":
            if (vwapRef.current) {
              chartRef.current.removeSeries(vwapRef.current);
              vwapRef.current = null;
            }
            break;
        }
      } catch (error) {
        console.warn("Error in handleToolChange:", error);
      }
    } else {
      setActiveTools((prev) => [...prev, tool]);
      switch (tool) {
        case "line":
          setDrawingLine(true);
          break;
        case "bollinger":
          addBollingerBands();
          break;
        case "vwap":
          addIndicators("vwap");
          break;
      }
    }
  };

  const getIndicatorColor = (type: string, period: string) => {
    type ColorMap = {
      [key: string]: string;
    };

    type IndicatorColors = {
      [key: string]: {
        colors: ColorMap;
      };
    };

    const baseColors: IndicatorColors = {
      ema: {
        colors: {
          "5": "#00C805",
          "12": "#FF8C00",
          "20": "#FFA500",
          "26": "#FF4500",
          "40": "#FF0000",
        },
      },
      sma: {
        colors: {
          "5": "#FF1493",
          "20": "#4169E1",
          "60": "#32CD32",
        },
      },
      rsi: {
        colors: {
          "7": "#9400D3",
          "14": "#FFD700",
          "21": "#20B2AA",
        },
      },
    };

    return baseColors[type]?.colors[period] || "#000000";
  };

  useEffect(() => {
    if (!chartRef.current || !techData?.length) return;

    if (emaSeriesRef.current) {
      emaSeriesRef.current.forEach((series) => {
        chartRef.current?.removeSeries(series);
      });
      emaSeriesRef.current = [];
    }

    if (smaSeriesRef.current) {
      smaSeriesRef.current.forEach((series) => {
        chartRef.current?.removeSeries(series);
      });
      smaSeriesRef.current = [];
    }

    if (rsiRef.current.length > 0) {
      rsiRef.current.forEach((series) => {
        chartRef.current?.removeSeries(series);
      });
      rsiRef.current = [];
    }

    selectedEMA.forEach((period) => {
      const emaData = techData
        .map((d) => ({
          time: d.date.substring(0, 10),
          value: d[`EMA${period}` as keyof TechData],
        }))
        .filter((d) => d.value !== null && typeof d.value === "number");

      const emaSeries = chartRef.current?.addLineSeries({
        color: getIndicatorColor("ema", period),
        lineWidth: 3,
        title: `EMA ${period}`,
        lastValueVisible: true,
        priceLineVisible: false,
      });
      if (emaSeries) {
        emaSeries.setData(emaData);
        emaSeriesRef.current.push(emaSeries);
      }
    });

    selectedSMA.forEach((period) => {
      const smaData = techData
        .map((d) => ({
          time: d.date.substring(0, 10),
          value: d[`SMA${period}` as keyof TechData],
        }))
        .filter((d) => d.value !== null && typeof d.value === "number");

      const smaSeries = chartRef.current?.addLineSeries({
        color: getIndicatorColor("sma", period),
        lineWidth: 3,
        title: `SMA ${period}`,
        lastValueVisible: true,
        priceLineVisible: false,
      });
      if (smaSeries) {
        smaSeries.setData(smaData);
        smaSeriesRef.current.push(smaSeries);
      }
    });

    selectedRSI.forEach((period) => {
      const rsiData = techData
        .map((d) => ({
          time: d.date.substring(0, 10),
          value: d[`RSI${period}` as keyof TechData],
        }))
        .filter((d) => d.value !== null && typeof d.value === "number");

      const rsiSeries = chartRef.current?.addLineSeries({
        color: getIndicatorColor("rsi", period),
        lineWidth: 3,
        title: `RSI ${period}`,
        lastValueVisible: true,
        priceLineVisible: false,
      });
      if (rsiSeries) {
        rsiSeries.setData(rsiData);
        rsiRef.current.push(rsiSeries);
      }
    });

    return () => {
      if (chartRef.current) {
        if (emaSeriesRef.current.length > 0) {
          emaSeriesRef.current.forEach((series) => {
            chartRef.current?.removeSeries(series);
          });
          emaSeriesRef.current = [];
        }
        if (smaSeriesRef.current.length > 0) {
          smaSeriesRef.current.forEach((series) => {
            chartRef.current?.removeSeries(series);
          });
          smaSeriesRef.current = [];
        }
        if (rsiRef.current.length > 0) {
          rsiRef.current.forEach((series) => {
            chartRef.current?.removeSeries(series);
          });
          rsiRef.current = [];
        }
      }
    };
  }, [techData, selectedEMA, selectedSMA, selectedRSI]);

  useEffect(() => {
    if (!chartData?.length) return;

    const fetchTechData = async () => {
      try {
        const response = await getTechDailyData({
          ticker,
          ema: selectedEMA,
          rsi: selectedRSI,
          sma: selectedSMA,
        });
        setTechData(response?.data?.array || []);
      } catch (error) {
        console.error("Failed to fetch technical data:", error);
        setTechData([]);
      }
    };

    fetchTechData();
  }, [chartData, ticker, market, selectedEMA, selectedRSI, selectedSMA]);

  const handleCreateGroup = (values: any) => {
    const newGroup: IndicatorGroup = {
      id: Date.now().toString(),
      name: values.name,
      indicators: [
        {
          type: "ema",
          periods: values.ema || [],
        },
        {
          type: "sma",
          periods: values.sma || [],
        },
        {
          type: "rsi",
          periods: values.rsi || [],
        },
      ],
    };
    setGroups([...groups, newGroup]);
    setIsModalVisible(false);
    form.resetFields();
  };

  const isGroupSelected = (group: IndicatorGroup) => {
    if (!group.indicators?.length) return false;
    return group.indicators.every((indicator) => {
      if (indicator.periods.length === 0) return true;
      switch (indicator.type) {
        case "ema":
          return indicator.periods.every((p) => selectedEMA.includes(p));
        case "sma":
          return indicator.periods.every((p) => selectedSMA.includes(p));
        case "rsi":
          return indicator.periods.every((p) => selectedRSI.includes(p));
        default:
          return false;
      }
    });
  };

  const handleGroupClick = (group: IndicatorGroup) => {
    if (group.name === "GEX" && group.data) {
      if (activeGexLines.length > 0) {
        activeGexLines.forEach((line) => {
          chartRef.current?.removeSeries(line);
        });
        setActiveGexLines([]);
        return;
      }
      console.log("Ticker:", ticker);
      console.log("Available keys:", Object.keys(group.data));

      const stockData = group.data[ticker.toUpperCase()];

      if (stockData) {
        Object.entries(stockData).forEach(([key, value]) => {
          if (key === "Implied Movement") {
            const impliedMovement = value as ImpliedMovement;
            Object.entries(impliedMovement).forEach(([label, price]) => {
              const line = drawPriceLine(price, `${key} ${label}`);
              if (line) setActiveGexLines((prev) => [...prev, line]);
            });
          } else {
            const line = drawPriceLine(value as number, key);
            if (line) setActiveGexLines((prev) => [...prev, line]);
          }
        });
        return;
      }
    }

    if (!group.indicators?.length) return;
    if (isGroupSelected(group)) {
      setSelectedEMA([]);
      setSelectedSMA([]);
      setSelectedRSI([]);
      return;
    }

    setSelectedEMA([]);
    setSelectedSMA([]);
    setSelectedRSI([]);

    setTimeout(() => {
      group.indicators?.forEach((indicator) => {
        if (indicator.periods.length > 0) {
          switch (indicator.type) {
            case "ema":
              setSelectedEMA(indicator.periods);
              break;
            case "sma":
              setSelectedSMA(indicator.periods);
              break;
            case "rsi":
              setSelectedRSI(indicator.periods);
              break;
          }
        }
      });
    }, 0);
  };

  const drawPriceLine = (
    price: number,
    name: string
  ): ISeriesApi<"Line"> | null => {
    if (!chartRef.current || !chartData.length) return null;

    const lineSeries = chartRef.current.addLineSeries({
      color: getLineColor(name),
      lineWidth: 1,
      lineStyle: LineStyle.Solid,
      title: name,
      lastValueVisible: true,
      priceLineVisible: false,
    });

    lineSeries.setData([
      { time: chartData[0].time, value: price },
      { time: chartData[chartData.length - 1].time, value: price },
    ]);

    return lineSeries;
  };

  const getLineColor = (name: string) => {
    if (name.includes("Put Wall")) return "#ef5350";
    if (name.includes("Call Wall")) return "#26a69a";
    if (name.includes("Gamma Flip")) return "#ff9800";
    if (name.includes("Key Delta")) return "#9c27b0";
    if (name.includes("Dominate")) return "#2196f3";
    if (name.includes("Large Gamma")) return "#795548";
    if (name.includes("-2σ")) return "#f44336";
    if (name.includes("-σ")) return "#ff5722";
    if (name.includes("+σ")) return "#4caf50";
    if (name.includes("+2σ")) return "#1b5e20";
  };

  useEffect(() => {
    setGroups(defaultGroups[market]);
  }, [market]);

  return (
    <div className="stock-chart-container">
      <div className="chart-tools">
        <div className="indicators" ref={dropdownRef}>
          <div className="tool-title">工具</div>
          <Button
            type={activeTools.includes("line") ? "primary" : "default"}
            onClick={() => handleToolChange("line")}
          >
            畫線
          </Button>
          <div className="dropdown-button">
            <Button
              type={selectedEMA.length > 0 ? "primary" : "default"}
              onClick={() => handleToolChange("ema")}
            >
              EMA
            </Button>
            {activeTools.includes("ema") && (
              <div className="dropdown-content">
                <Checkbox.Group
                  options={emaOptions as CheckboxOptionType[]}
                  value={selectedEMA as unknown as CheckboxOptionType[]}
                  onChange={handleEMAChange}
                />
              </div>
            )}
          </div>
          <div className="dropdown-button">
            <Button
              type={selectedSMA.length > 0 ? "primary" : "default"}
              onClick={() => handleToolChange("sma")}
            >
              SMA
            </Button>
            {activeTools.includes("sma") && (
              <div className="dropdown-content">
                <Checkbox.Group
                  options={smaOptions as CheckboxOptionType[]}
                  value={selectedSMA as unknown as CheckboxOptionType[]}
                  onChange={handleSMAChange}
                />
              </div>
            )}
          </div>
          <div className="dropdown-button">
            <Button
              type={selectedRSI.length > 0 ? "primary" : "default"}
              onClick={() => handleToolChange("rsi")}
            >
              RSI
            </Button>
            {activeTools.includes("rsi") && (
              <div className="dropdown-content">
                <Checkbox.Group
                  options={rsiOptions as CheckboxOptionType[]}
                  value={selectedRSI as unknown as CheckboxOptionType[]}
                  onChange={handleRSIChange}
                />
              </div>
            )}
          </div>
          <Button
            type={activeTools.includes("vwap") ? "primary" : "default"}
            onClick={() => handleToolChange("vwap")}
          >
            VWAP
          </Button>
        </div>
        <div className="groups">
          <div className="tool-title">群組</div>
          <Button
            type={activeTools.includes("bollinger") ? "primary" : "default"}
            onClick={() => handleToolChange("bollinger")}
          >
            布林通道
          </Button>
          <Button
            type={activeTools.includes("ema-combo") ? "primary" : "default"}
            onClick={() => handleToolChange("ema-combo")}
          >
            EMA Combos
          </Button>
          {groups.map((group) => (
            <Button
              key={group.id}
              onClick={() => handleGroupClick(group)}
              type={
                (group.name === "GEX" && activeGexLines.length > 0) ||
                (group.name !== "GEX" && isGroupSelected(group))
                  ? "primary"
                  : "default"
              }
            >
              {group.name}
            </Button>
          ))}
          <Button
            onClick={() => setIsModalVisible(true)}
            style={{ color: "#58c2a9", borderColor: "#58c2a9" }}
          >
            新增群組
          </Button>
        </div>
      </div>
      <Spin
        spinning={loading}
        size="large"
        delay={300}
        wrapperClassName="gray-spin"
        style={{
          background: "rgba(255, 255, 255, 0.5)",
        }}
      >
        <div
          ref={chartContainerRef}
          className="stock-chart"
          style={{
            height: "50vh",
            minHeight: "300px",
          }}
          onClick={handleChartClick}
        />
      </Spin>
      <Modal
        title="新增指標群組"
        open={isModalVisible}
        onOk={() => form.submit()}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} onFinish={handleCreateGroup} layout="vertical">
          <Form.Item name="name" label="群組名稱" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="ema" label="EMA指標">
            <Checkbox.Group options={emaOptions} />
          </Form.Item>
          <Form.Item name="sma" label="SMA指標">
            <Checkbox.Group options={smaOptions} />
          </Form.Item>
          <Form.Item name="rsi" label="RSI指標">
            <Checkbox.Group options={rsiOptions} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default StockChart;
