import { useEffect, useState } from "react";
import StockChart from "../components/StockChart";
import StockDetailsCard from "../components/Landing/StockDetailsCard";
import { getTechDailyData } from "../server/Tech/index";
import { getValuationOverviewData } from "../server/Valuation";
import { getTechQuarterlyData } from "../server/Tech/index";

const infoFive = {
  市值: "27.62兆",
  "企業價值(EV)": "",
  過去4季EPS: "35.61元",
  過去4季每股FCF: "27.77元",
};

type OverviewProps = {
  ticker: string;
  market: "tw" | "us";
};

interface KlineItem {
  date: string;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
}

const Overview = ({ ticker, market }: OverviewProps) => {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<
    Array<{
      time: string;
      open: number;
      high: number;
      low: number;
      close: number;
      volume: number;
    }>
  >([]);

  const [infoOne, setInfoOne] = useState({
    收盤: "-",
    前日收盤: "-",
    漲跌: "-",
    漲跌幅: "-",
  });
  const [infoTwo, setInfoTwo] = useState({
    開盤: "-",
    最高: "-",
    最低: "-",
    振幅: "-",
  });
  const [infoThree, setInfoThree] = useState({
    成交金額: "-",
    "成交量(張)": "-",
    "3個月平均成交量(張)": "-",
    最新股數: "-",
  });
  const [infoFour, setInfoFour] = useState({
    "P/E": "-",
    "P/B": "-",
    "P/FCF": "-",
    現金股利殖利率: "-",
  });

  const calculateAverageVolume = (quarterlyData: any[]) => {
    if (quarterlyData.length === 0) return 0;

    const totalVolume = quarterlyData
      .slice(0, 3)
      .reduce((acc, curr) => acc + curr.volume, 0);

    return totalVolume / 3;
  };
  const formatInBillion = (value: number) => {
    return `${(value / 100000000).toFixed(2)} 億`;
  };

  const fetchData = async () => {
    if (!ticker) return;

    setLoading(true);
    try {
      const chartResponse = await getTechDailyData({
        ticker,
      });
      const klineData = chartResponse.data?.array || [];

      if (klineData.length > 0) {
        const formattedData = klineData.map((item: KlineItem) => ({
          time: item.date.substring(0, 10),
          open: item.open,
          high: item.high,
          low: item.low,
          close: item.close,
          volume: item.volume,
        }));
        setChartData(formattedData);

        const latestData = formattedData[formattedData.length - 1];
        const prevDayData =
          formattedData[formattedData.length - 2] || latestData;

        setInfoOne({
          收盤: latestData.close.toLocaleString(),
          前日收盤: prevDayData.close.toLocaleString(),
          漲跌: `${latestData.close - prevDayData.close >= 0 ? "+" : ""}${(
            latestData.close - prevDayData.close
          ).toFixed(2)}`,
          漲跌幅: `${latestData.close - prevDayData.close >= 0 ? "+" : ""}${(
            ((latestData.close - prevDayData.close) / prevDayData.close) *
            100
          ).toFixed(2)}%`,
        });

        setInfoTwo({
          開盤: latestData.open.toLocaleString(),
          最高: latestData.high.toLocaleString(),
          最低: latestData.low.toLocaleString(),
          振幅: `${latestData.high - latestData.low}`,
        });

        const quarterlyResponse = await getTechQuarterlyData({
          ticker,
        });
        const quarterlyData = quarterlyResponse.data?.array || [];

        if (quarterlyData.length > 0) {
          const latestVolume = quarterlyData[0].volume;
          const latestClosePrice = quarterlyData[0].close;

          const transactionAmount = latestVolume * latestClosePrice;

          const averageVolume = calculateAverageVolume(quarterlyData);

          setInfoThree({
            成交金額: formatInBillion(transactionAmount),
            "成交量(張)": formatInBillion(latestVolume),
            "3個月平均成交量(張)": formatInBillion(averageVolume),
            最新股數: "-",
          });
        }

        const valuationResponse = await getValuationOverviewData({
          ticker,
        });
        const valuationData = valuationResponse.data || {};

        setInfoFour({
          "P/E": valuationData.P_E ?? "-",
          "P/B": valuationData.P_B ?? "-",
          "P/FCF": valuationData.P_FCF.toFixed(2) ?? "-",
          現金股利殖利率: "-",
        });
      }
    } catch (error) {
      console.error("Data request error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [ticker]);

  return (
    <>
      <div className="stock-watchlist-container">
        <div className="stock-card">
          <div className="stock-info-wrapper">
            <h2>股價資訊</h2>
            <div className="stock-info">
              <StockChart
                chartData={chartData}
                loading={loading}
                ticker={ticker}
                market={market}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="stock-details-container">
        <StockDetailsCard set={infoOne} />
        <StockDetailsCard set={infoTwo} />
        <StockDetailsCard set={infoThree} />
        <StockDetailsCard set={infoFour} />
        <StockDetailsCard set={infoFive} />
      </div>
    </>
  );
};

export default Overview;
