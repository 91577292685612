import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StockChart from "../../components/StockChart";
import { getTechDailyData } from "../../server/Tech/index";

interface KlineItem {
  date: string;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
}

const StockChartPage = () => {
  const { ticker } = useParams();
  const market = window.location.pathname.includes("/us-stock/") ? "us" : "tw";
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<
    Array<{
      time: string;
      open: number;
      high: number;
      low: number;
      close: number;
      volume: number;
    }>
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!ticker) return;

      setLoading(true);
      try {
        const response = await getTechDailyData({ ticker });
        if (response.data?.array) {
          const formattedData = response.data.array.map((item: KlineItem) => ({
            time: item.date.substring(0, 10),
            open: item.open,
            high: item.high,
            low: item.low,
            close: item.close,
            volume: item.volume,
          }));
          setChartData(formattedData);
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [ticker]);

  return (
    <div className="stock-chart-page">
      <h2>{ticker} 股價走勢圖</h2>
      <div className="chart-wrapper">
        <StockChart
          chartData={chartData}
          loading={loading}
          ticker={ticker as string}
          market={market}
        />
      </div>
    </div>
  );
};

export default StockChartPage;
