import axiosClient from "../axios";

interface tickerBlob {
  ticker: string;
  ema?: string[];
  rsi?: string[];
  sma?: string[];
}

const tech = "/neurostats/tech/";

// **Tech
// Get Vitals
export const getTechVitalsData = (tickerBlob: tickerBlob) => {
  const url = `${tech}vitals`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Daily
export const getTechDailyData = async (params: tickerBlob) => {
  const url = `${tech}daily`;
  const body = params;
  return axiosClient.post<any>(url, body);
};

// Get Weekly
export const getTechWeeklyData = (tickerBlob: tickerBlob) => {
  const url = `${tech}weekly`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Monthly
export const getTechMonthlyData = (tickerBlob: tickerBlob) => {
  const url = `${tech}monthly`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Quarterly
export const getTechQuarterlyData = (tickerBlob: tickerBlob) => {
  const url = `${tech}quarterly`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Yearly
export const getTechYearlyData = (tickerBlob: tickerBlob) => {
  const url = `${tech}yearly`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};
